.section__title {
  font-family: "Gilroy";
  font-size: 40px;
  font-weight: 900;
  line-height: 1.2;
  color: #656565;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 75px 0;
}
.section__title-span {
  color: #ff5150;
}
.vk__container {
  margin: auto;
  position: relative;
  z-index: 10;
  width: 75% !important;
  overflow: hidden;
}
.case_art__button {
  display: flex;
  width: 159px;
  height: 30px;
  font-size: 21.7px;
  font-family: "Gilroy";
  font-weight: 500;
  line-height: 1.2;
  margin: 20px auto 0 auto;
  text-align: center;
  color: #1a1b22;
  background: #ffffff;
  border-radius: 80px;
  outline: none;
  border: none;
  cursor: pointer;
}
.case_art__button:hover {
  color: #ff5150;
}
.case_art__button_disabled {
  display: none;
}
.autoblick {
  box-shadow: none;
  -webkit-animation: si-button-pulse 2s linear infinite;
  -moz-animation: si-button-pulse 2s linear infinite;
  animation: si-button-pulse 2s linear infinite;
  overflow: hidden;
}
.autoblick_small {
  box-shadow: none;
  -webkit-animation: si-button-pulse-small 2s linear infinite;
  -moz-animation: si-button-pulse-small 2s linear infinite;
  animation: si-button-pulse-small 2s linear infinite;
  overflow: hidden;
}
.autoblickblue {
  box-shadow: none;
  -webkit-animation: si-button-pulse-blue 2s linear infinite;
  -moz-animation: si-button-pulse-blue 2s linear infinite;
  animation: si-button-pulse-blue 2s linear infinite;
  overflow: hidden;
}
.autoblickgrey {
  box-shadow: none;
  -webkit-animation: si-button-pulse-grey 2s linear infinite;
  -moz-animation: si-button-pulse-grey 2s linear infinite;
  animation: si-button-pulse-grey 2s linear infinite;
  overflow: hidden;
}
.button__text {
  margin: auto;
  position: relative;
  z-index: 1;
}
.circleflash {
  position: relative;
  overflow: hidden;
  transition: all 0.8s ease-in-out;
}
.circleflash span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: #ffffff;
  transition: width 0.8s ease-in-out, height 0.8s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.circleflash:hover {
  color: #000000 !important;
}
.circleflash:hover span {
  width: 225%;
  height: 562.5px;
}
.circleflash:active {
  background-color: #e2e2e2;
}
.article__img {
  max-width: 100%;
}
.article__text {
  width: 100%;
  text-align: justify;
  font-family: "Gilroy";
  font-size: 21px;
  font-weight: 500;
  line-height: 1.2;
  margin: 10px 0 0 0;
}
.article__link {
  color: #000000;
  width: 100%;
  text-align: justify;
  font-family: "Gilroy";
  font-size: 21px;
  font-weight: 500;
  line-height: 1.2;
  margin: 10px 0 0 0;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: all 1s linear;
}
.article__link:hover {
  color: #ff5150;
  transition: all 1s linear;
}
.article__comment {
  width: 100%;
  color: #656565;
  text-align: center;
  font-family: "Gilroy";
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 10px 0;
}
.article__subtitle {
  width: 100%;
  font-family: "Gilroy";
  font-weight: 700;
  line-height: 1.2;
  font-size: 21px;
  text-align: center;
  margin: 10px 0;
}
.article__header-box {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin: 0 auto 0 0;
  cursor: pointer;
}
.article__owner_img {
  border-radius: 25px;
  margin: 0 10px;
}
.article__owner_name {
  font-family: "Gilroy";
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  color: #000;
  text-align: left;
  margin: 0 0 75px 0;
  text-decoration: none;
  cursor: pointer;
}
.article__date {
  font-family: "Gilroy";
  font-size: 15px;
  font-weight: 500;
  line-height: 1.2;
  color: #000;
  text-align: left;
  margin: 0 0 75px 0;
}
@keyframes si-button-pulse {
  from {
    -webkit-box-shadow: 0px 0px 25px 25px rgba(255, 81, 80, 0.4);
    -moz-box-shadow: 0px 0px 25px 25px rgba(255, 81, 80, 0.4);
    box-shadow: 0px 0px 25px 25px rgba(255, 81, 80, 0.4);
  }

  to {
    -webkit-box-shadow: 0px 0px 15px 15px rgba(255, 81, 80, 0);
    -moz-box-shadow: 0px 0px 15px 15px rgba(255, 81, 80, 0);
    box-shadow: 0px 0px 15px 15px rgba(255, 81, 80, 0);
  }
}
@keyframes si-button-pulse-small {
  from {
    -webkit-box-shadow: 0px 0px 5px 5px rgba(255, 81, 80, 0.4);
    -moz-box-shadow: 0px 0px 5px 5px rgba(255, 81, 80, 0.4);
    box-shadow: 0px 0px 5px 5px rgba(255, 81, 80, 0.4);
  }

  to {
    -webkit-box-shadow: 0px 0px 15px 15px rgba(255, 81, 80, 0);
    -moz-box-shadow: 0px 0px 15px 15px rgba(255, 81, 80, 0);
    box-shadow: 0px 0px 15px 15px rgba(255, 81, 80, 0);
  }
}
@keyframes si-button-pulse-blue {
  from {
    -webkit-box-shadow: 0px 0px 25px 25px rgba(61, 87, 121, 0.4);
    -moz-box-shadow: 0px 0px 25px 25px rgba(61, 87, 121, 0.4);
    box-shadow: 0px 0px 25px 25px rgba(61, 87, 121, 0.4);
  }

  to {
    -webkit-box-shadow: 0px 0px 10px 10px rgba(61, 87, 121, 0);
    -moz-box-shadow: 0px 0px 10px 10px rgba(61, 87, 121, 0);
    box-shadow: 0px 0px 10px 10px rgba(61, 87, 121, 0);
  }
}
@keyframes si-button-pulse-grey {
  from {
    -webkit-box-shadow: 0px 0px 25px 25px rgba(172, 179, 162, 0.4);
    -moz-box-shadow: 0px 0px 25px 25px rgba(172, 179, 162, 0.4);
    box-shadow: 0px 0px 25px 25px rgba(172, 179, 162, 0.4);
  }

  to {
    -webkit-box-shadow: 0px 0px 10px 10px rgba(61, 87, 121, 0);
    -moz-box-shadow: 0px 0px 10px 10px rgba(61, 87, 121, 0);
    box-shadow: 0px 0px 10px 10px rgba(61, 87, 121, 0);
  }
}
@media screen and (max-width: 867px) {
  .section__title {
    font-size: 35px;
    margin: 0 auto 40px auto;
    max-width: 463px;
  }
}
@media screen and (max-width: 433px) {
  .section__title {
    font-size: 30px;
    margin: 0 0 40px 0;
  }
}
@media screen and (max-width: 340px) {
  .section__title {
    font-size: 25px;
    margin: 0 0 40px 0;
  }

  @keyframes si-button-pulse {
    from {
      -webkit-box-shadow: 0px 0px 10px 10px rgba(255, 81, 80, 0.4);
      -moz-box-shadow: 0px 0px 10px 10px rgba(255, 81, 80, 0.4);
      box-shadow: 0px 0px 10px 10px rgba(255, 81, 80, 0.4);
    }

    to {
      -webkit-box-shadow: 0px 0px 5px 5px rgba(255, 81, 80, 0);
      -moz-box-shadow: 0px 0px 5px 5px rgba(255, 81, 80, 0);
      box-shadow: 0px 0px 5px 5px rgba(255, 81, 80, 0);
    }
  }
  @keyframes si-button-pulse-blue {
    from {
      -webkit-box-shadow: 0px 0px 10px 10px rgba(61, 87, 121, 0.4);
      -moz-box-shadow: 0px 0px 10px 10px rgba(61, 87, 121, 0.4);
      box-shadow: 0px 0px 10px 10px rgba(61, 87, 121, 0.4);
    }

    to {
      -webkit-box-shadow: 0px 0px 5px 5px rgba(61, 87, 121, 0);
      -moz-box-shadow: 0px 0px 5px 5px rgba(61, 87, 121, 0);
      box-shadow: 0px 0px 5px 5px rgba(61, 87, 121, 0);
    }
  }
  @keyframes si-button-pulse-grey {
    from {
      -webkit-box-shadow: 0px 0px 10px 10px rgba(172, 179, 162, 0.4);
      -moz-box-shadow: 0px 0px 10px 10px rgba(172, 179, 162, 0.4);
      box-shadow: 0px 0px 10px 10px rgba(172, 179, 162, 0.4);
    }

    to {
      -webkit-box-shadow: 0px 0px 5px 5px rgba(61, 87, 121, 0);
      -moz-box-shadow: 0px 0px 5px 5px rgba(61, 87, 121, 0);
      box-shadow: 0px 0px 5px 5px rgba(61, 87, 121, 0);
    }
  }
}
