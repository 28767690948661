.error-page {
  background-image: url(../../images/main.png);
  background-image: image-set(
    url(../../images/mainload.png) type("image/png") 1x,
    url(../../images/mainload.avif) type("image/avif") 1x,
    url(../../images/mainload.webp) type("image/webp") 1x
  );
  background-image: -webkit-image-set(
    url(../../images/mainload.png) 1x,
    url(../../images/mainload.avif) 1x,
    url(../../images/mainload.webp) 1x
  );
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding: 101px 20px 500px 20px;
}
.error-page__box {
  max-width: 1170px;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 auto;
  flex-direction: column;
}
.error-page__title {
  max-width: 707px;
  font-size: 51px;
  line-height: 1.2;
  font-weight: bolder;
  color: #ffffff;
  margin: 100px 0 40px 0;
  text-align: left;
  font-family: "Gilroy";
  font-weight: 900;
}
.error-page__subtitle {
  position: relative;
  max-width: 622px;
  font-size: 20.5px;
  line-height: 1.2;
  color: #ffffff;
  margin: 0;
  text-align: left;
  font-family: "Gilroy";
  font-weight: 700;
}
.error-page__button {
  cursor: pointer;
  background: rgb(231, 40, 39);
  background: linear-gradient(
    0deg,
    rgba(231, 40, 39, 1) 0%,
    rgba(254, 91, 91, 1) 100%
  );
  -webkit-appearance: none;
  color: #ffffff;
  outline: none;
  border: none;
  border-radius: 52px;
  width: 450px;
  height: 80px;
  font-size: 22.4px;
  margin: 100px 0 0 0;
  font-family: "Gilroy";
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0;
}
.error-page__text {
  max-width: 183px;
  font-size: 16px;
  font-family: "Gilroy";
  font-weight: 400;
  margin: 80px 0 0 135px;
  color: #ffffff;
}
.error-page__animation {
  width: 39px;
  height: 39px;
  margin: 36px 0 0 200px;
}
@media screen and (max-width: 1163px) {
  .error-page {
    padding: 101px 20px 350px 20px;
  }
  .error-page__title {
    max-width: 552px;
    font-size: 40px;
  }
  .error-page__subtitle {
    max-width: 552px;
    font-size: 18px;
  }
  .error-page__button {
    margin: 50px 0 0 0;
  }
}
@media screen and (max-width: 900px) {
  .error-page__title {
    max-width: 464px;
    font-size: 30px;
    margin: 100px 0 30px 0;
  }
  .error-page__subtitle {
    max-width: 450px;
  }
  .error-page__button {
    width: 320px;
    height: 74px;
    font-size: 18px;
  }
}
@media screen and (max-width: 698px) {
  .error-page {
    background-image: url(../../images/main2.png);
    background-image: image-set(
      url(../../images/main2load.png) type("image/png") 1x,
      url(../../images/main2load.avif) type("image/avif") 1x,
      url(../../images/main2load.webp) type("image/webp") 1x
    );
    background-image: -webkit-image-set(
      url(../../images/main2load.png) 1x,
      url(../../images/main2load.avif) 1x,
      url(../../images/main2load.webp) 1x
    );
    padding: 100px 20px 165px;
    height: 100vh;
  }
  .error-page__title {
    max-width: 526px;
    margin: 40px 0;
  }
  .error-page__button {
    width: 300px;
    font-size: 15px;
    margin: 50px 0 0 0;
  }
  .error-page__text {
    max-width: 103px;
    text-align: center;
    margin: 80px 0 0 20px;
  }
  .error-page__animation {
    margin: 36px 0 0 50px;
  }
}
@media screen and (max-width: 544px) {
  .error-page {
    padding: 100px 20px 240px;
  }
}
@media screen and (max-width: 500px) {
  .error-page {
    padding: 100px 20px 170px;
  }
  .error-page__title {
    margin: 20px 0;
  }
  .error-page__button {
    margin: 30px 0 0 0;
  }
}
@media screen and (max-width: 400px) {
  .error-page {
    padding: 100px 20px 200px;
  }
}
@media screen and (max-width: 422px) {
  .error-page__title {
    font-size: 25px;
  }
  .error-page__subtitle {
    font-size: 18px;
  }
  .error-page__button {
    margin: 45px 0 0 0;
  }
}
@media screen and (max-width: 358px) {
  .error-page__title {
    margin: 30px 0 20px 0;
    font-size: 20px;
  }
  .error-page__subtitle {
    font-size: 15px;
  }
  .error-page__button {
    width: 280px;
    margin: 45px 0 0 0;
  }
}
