.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../../images/footer.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -298px;
  padding: 524px 20px 40px 20px;
}
.footer__title {
  margin: 0 0 50px 0;
}
.footer__subtitle {
  font-family: "Gilroy";
  font-size: 21.7px;
  font-weight: 400;
  line-height: 1.2;
  color: #fff;
  text-align: center;
  margin: 0 0 20px 0;
  max-width: 370px;
}
.footer__link {
  margin: 0;
  font-size: 35px;
  line-height: 1.2;
  text-align: left;
  color: #fff;
  font-family: "Gilroy";
  font-weight: 500;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
.footer__link:hover {
  color: #fa5251;
}
.footer__box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  max-width: 370px;
  margin: 30px 0;
}
.footer__social-link {
  width: 57px;
  margin: 0 15px;
  height: 57px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.footer__social-link:hover {
  opacity: 0.7;
}
.footer__social-link:nth-of-type(1) {
  background-image: url(../../images/icon/vk.png);
}
.footer__social-link:nth-of-type(2) {
  background-image: url(../../images/icon/whatsapp.png);
}
.footer__social-link:nth-of-type(3) {
  background-image: url(../../images/icon/youtube.png);
}
.footer__social-link:nth-of-type(4) {
  background-image: url(../../images/icon/telegram.png);
}
.footer__logo {
  width: 86px;
  height: 81px;
}
.footer__policy {
  margin: 50px 0 10px 0;
  font-family: "Gilroy";
  font-size: 13px;
  font-weight: 400;
  line-height: 1.2;
  color: #fff;
  text-align: center;
  max-width: 192px;
}
.footer__text {
  font-family: "Gilroy";
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  color: #fff;
  text-align: center;
  margin: 0;
  max-width: 458px;
}
@media screen and (max-width: 1004px) {
  .footer {
    margin-top: -298px;
  }
}
@media screen and (max-width: 924px) {
  .footer {
    margin-top: -40%;
  }
}
@media screen and (max-width: 846px) {
  .footer {
    margin-top: -45%;
  }
}
@media screen and (max-width: 796px) {
  .footer {
    margin-top: -50%;
  }
}
@media screen and (max-width: 750px) {
  .footer {
    margin-top: -55%;
  }
}
@media screen and (max-width: 707px) {
  .footer {
    margin-top: -60%;
  }
}
@media screen and (max-width: 548px) {
  .footer {
    margin-top: -346px;
    padding: 524px 20px 40px 20px;
  }
}
@media screen and (max-width: 355px) {
  .footer__social-link {
    width: 50px;
    height: 50px;
  }
}
