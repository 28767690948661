body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body::-webkit-scrollbar {
  width: 10px; /* ширина scrollbar */
}
body::-webkit-scrollbar-track {
  display: none;
  opacity: 0; /* цвет дорожки */
}
body::-webkit-scrollbar-thumb {
  background-color: #ff5150; /* цвет плашки */
  border-radius: 20px; /* закругления плашки */
  border: 3px solid #fff; /* padding вокруг плашки */
}
