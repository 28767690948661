.header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.header__box {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
}
.header_logo {
  width: 86px;
  height: 81px;
  margin: 0 31px 0 0;
}
.header_logo-text {
  max-width: 99px;
  font-size: 18px;
  line-height: 1.2;
  text-align: left;
  color: #ffffff;
  font-family: "Gilroy";
  font-weight: 500;
}
.header_text {
  margin: 0 0 0 31px;
  font-size: 18px;
  line-height: 1.2;
  text-align: left;
  color: #fa5251;
  font-family: "Gilroy";
  font-weight: 300;
}
.header_text-adress {
  max-width: 255px;
  margin: 0 0 0 15px;
  font-size: 18px;
  line-height: 1.2;
  text-align: left;
  color: #fff;
  font-family: "Gilroy";
  font-weight: 300;
}
.header_text-span {
  color: #ffffff;
}
.header__box-mess {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 0 0 0 20px;
}
.header__social-link {
  margin: 0 5px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  transition: opacity linear 1s;
  border-radius: 50%;
}
.header__social-link:nth-of-type(1) {
  background: url(../../images/icon/phone.svg),
    linear-gradient(0deg, rgba(231, 40, 39, 1) 0%, rgba(254, 91, 91, 1) 100%);
  background-position: center;
  background-repeat: no-repeat;
}
.header__social-link:nth-of-type(2) {
  background-image: url(../../images/icon/whatsapp.svg);
}
.header__social-link:nth-of-type(3) {
  background-image: url(../../images/icon/telega.svg);
}
.header__social-link:hover {
  opacity: 0.8;
  transition: opacity linear 0.5s;
}
@media screen and (max-width: 643px) {
  .header_logo {
    width: 66px;
    height: 61px;
    margin: 0 5px 0 0;
  }
  .header_logo-text {
    max-width: 66px;
    font-size: 13px;
  }
  .header_text {
    margin: 0 0 0 15px;
    font-size: 13px;
  }
}
@media screen and (max-width: 497px) {
  .header_text {
    display: none;
  }
}
@media screen and (max-width: 347px) {
  .header__box {
    padding: 0 6px;
  }
}
