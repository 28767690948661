.referal {
  background-image: url(../../images/mainref.png);
  background-image: image-set(
    url(../../images/mainrefload.png) type("image/png") 1x,
    url(../../images/mainrefload.avif) type("image/avif") 1x,
    url(../../images/mainrefload.webp) type("image/webp") 1x
  );
  background-image: -webkit-image-set(
    url(../../images/mainrefload.png) 1x,
    url(../../images/mainrefload.avif) 1x,
    url(../../images/mainrefload.webp) 1x
  );
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding: 101px 20px 580px 20px;
}
.referal__box {
  max-width: 1170px;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 auto;
  flex-direction: column;
}
.referal__title {
  max-width: 707px;
  font-size: 51px;
  line-height: 1.2;
  font-weight: bolder;
  color: #ffffff;
  margin: 100px 0 40px 0;
  text-align: left;
  font-family: "Gilroy";
  font-weight: 900;
}
.referal__subtitle {
  position: relative;
  max-width: 622px;
  font-size: 20.5px;
  line-height: 1.2;
  color: #ffffff;
  margin: 0;
  text-align: left;
  font-family: "Gilroy";
  font-weight: 700;
}
.referal__button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: rgb(231, 40, 39);
  background: linear-gradient(
    0deg,
    rgba(231, 40, 39, 1) 0%,
    rgba(254, 91, 91, 1) 100%
  );
  -webkit-appearance: none;
  color: #ffffff;
  outline: none;
  border: none;
  border-radius: 52px;
  width: 450px;
  height: 80px;
  font-size: 22.4px;
  margin: 100px 0 0 0;
  font-family: "Gilroy";
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0;
}
.referal__text {
  max-width: 183px;
  font-size: 16px;
  font-family: "Gilroy";
  font-weight: 400;
  margin: 80px 0 0 135px;
  color: #ffffff;
}
.referal__animation {
  width: 39px;
  height: 39px;
  margin: 36px 0 0 200px;
}
.price-ref {
  margin-top: -50px;
  padding: 0px 20px 0 20px;
}
.price-ref__box {
  position: relative;
  width: 330px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: 0px 0px 10px 10px rgba(73, 73, 73, 0.1);
  -moz-box-shadow: 0px 0px 10px 10px rgba(73, 73, 73, 0.1);
  box-shadow: 0px 0px 10px 10px rgba(73, 73, 73, 0.1);
  border-radius: 25px;
  margin: 0 15px 55px 15px;
  z-index: 3;
}
.price-ref__title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.price-ref__img {
  object-fit: contain;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  margin: 0 10px 0 0;
}
.price-ref_container {
  max-width: 1170px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
}
.price-ref__title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gilroy";
  font-size: 16px;
  line-height: 1.2;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
  margin: 15px 0;
  max-width: 238px;
  min-height: 41px;
  text-transform: uppercase;
  border-bottom: 2px solid #ff5150c7;
}
.price-ref__about {
  font-family: "Gilroy";
  font-size: 16px;
  line-height: 1.2;
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding: 0 10px;
}
.price-ref__price-box {
  background-color: #ff5150;
  color: #fff;
  width: 100%;
  min-height: 53px;
  border-radius: 0 0 25px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.price-ref__price {
  font-family: "Gilroy";
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  text-align: center;
  margin: 10px 0;
}
.price-ref__button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  outline: none;
  width: 330px;
  min-height: 53px;
  border-radius: 0 0 25px 25px;
  background: none;
  background-color: #ff5150;
  border: none;
  border-left: 1px solid #fff;
  color: #fff;
  font-family: "Gilroy";
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  text-align: center;
  margin: 0;
  cursor: pointer;
  text-transform: uppercase;
}
.price-ref__button:hover {
  opacity: 0.8;
}

@media screen and (max-width: 1163px) {
  .referal {
    padding: 101px 20px 350px 20px;
  }
  .referal__title {
    max-width: 552px;
    font-size: 40px;
  }
  .referal__subtitle {
    max-width: 552px;
    font-size: 18px;
  }
  .referal__button {
    margin: 50px 0 0 0;
  }
}
@media screen and (max-width: 900px) {
  .referal__title {
    max-width: 464px;
    font-size: 30px;
    margin: 100px 0 30px 0;
  }
  .referal__subtitle {
    max-width: 450px;
  }
  .referal__button {
    width: 320px;
    height: 74px;
    font-size: 18px;
  }
}
@media screen and (max-device-width: 768px) {
  .price-ref {
    margin-top: -166px;
    padding: 0px 0 50px 0;
  }
  .price-ref_container {
    width: 100%;
    justify-content: flex-start;
    overflow: scroll;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
  }
  .price-ref_container::-webkit-scrollbar {
    display: none;
  }
  .price-ref__box {
    min-width: 260px;
    margin: 15px;
  }
  .price-ref__title {
    font-size: 15px;
    max-width: 224px;
  }
  .price-ref__about {
    font-size: 15px;
  }
}
@media screen and (max-width: 698px) {
  .referal {
    background-image: url(../../images/main2.png);
    background-image: image-set(
      url(../../images/main2load.png) type("image/png") 1x,
      url(../../images/main2load.avif) type("image/avif") 1x,
      url(../../images/main2load.webp) type("image/webp") 1x
    );
    background-image: -webkit-image-set(
      url(../../images/main2load.png) 1x,
      url(../../images/main2load.avif) 1x,
      url(../../images/main2load.webp) 1x
    );
    padding: 100px 20px 165px;
    height: 100vh;
  }
  .referal__title {
    max-width: 526px;
    margin: 40px 0;
  }
  .referal__button {
    width: 300px;
    font-size: 15px;
    margin: 50px 0 0 0;
  }
  .referal__text {
    max-width: 103px;
    text-align: center;
    margin: 80px 0 0 20px;
  }
  .referal__animation {
    margin: 36px 0 0 50px;
  }
}
@media screen and (max-width: 659px) {
  .price-ref__box {
    width: 250px;
    margin: 15px;
  }
  .price-ref__title {
    font-size: 13px;
  }
  .price-ref__about {
    font-size: 13px;
    margin: 0 0 10px 0;
  }
}
@media screen and (max-width: 559px) {
  .price-ref__box {
    width: 200px;
  }
  .price-ref__title {
    max-width: 189px;
    margin: 10px 0;
  }
  .price-ref__price {
    font-size: 13px;
  }
}
@media screen and (max-width: 544px) {
  .referal {
    padding: 100px 20px 240px;
  }
}
@media screen and (max-width: 500px) {
  .referal {
    padding: 100px 20px 170px;
  }
  .referal__title {
    margin: 20px 0;
  }
  .referal__button {
    margin: 30px 0 0 0;
  }
}
@media screen and (max-width: 400px) {
  .referal {
    padding: 100px 20px 200px;
  }
}
@media screen and (max-width: 422px) {
  .referal__title {
    font-size: 25px;
  }
  .referal__subtitle {
    font-size: 18px;
  }
  .referal__button {
    margin: 45px 0 0 0;
  }
}
@media screen and (max-width: 358px) {
  .referal__title {
    margin: 30px 0 20px 0;
    font-size: 20px;
  }
  .referal__subtitle {
    font-size: 15px;
  }
  .referal__button {
    width: 280px;
    margin: 45px 0 0 0;
  }
}
